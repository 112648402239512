import * as React from "react";
import { motion } from "framer-motion";

const Path = props => (
	<motion.path fill="#572148" strokeWidth="3" stroke="#572148" strokeLinecap="round" {...props} />
);

export const MenuToggle = ({ toggle, isOpen }) => (
	<button onClick={toggle} style={{ zIndex: "10000", border: "none", background: "transparent" }}>
		<svg width="23" height="23" viewBox="0 0 23 23">
			{/* <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      /> */}
			<Path
				animate={isOpen ? "open" : "closed"}
				variants={{
					closed: { d: "M 2 2.5 L 20 2.5" },
					open: { d: "M 3 16.5 L 17 2.5" }
				}}
			/>
			<Path
				d="M 2 9.423 L 20 9.423"
				animate={isOpen ? "open" : "closed"}
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 }
				}}
				transition={{ duration: 0.1 }}
			/>
			<Path
				animate={isOpen ? "open" : "closed"}
				variants={{
					closed: { d: "M 2 16.346 L 20 16.346" },
					open: { d: "M 3 2.5 L 17 16.346" }
				}}
			/>
		</svg>
		<style jsx>{`
			button:focus {
				box-shadow: none !important;
			}
		`}</style>
	</button>
);
