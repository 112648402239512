import React, { useState, useEffect, useRef, useContext } from 'react';
import DrawerMenu from './DrawerMenu.jsx';
import Link from "next/link";
import { useRouter } from "next/router";
import { theme, websiteColors } from "../../themes/Theme.js";
import { loginUrl, hostEventUrl } from '../../config/config.js';
import AuthContext from "../../contexts/AuthContext";
import dynamic from "next/dynamic";
import { handleOnLogin, successToaster } from "../../helpers/utils";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { removeCognitoFromLocalStorage } from "../../common/Functions";
import { useMediaQuery } from "react-responsive";
import { MenuToggle } from "../../components/Buttons/MenuToggle";
import { useCycle, motion } from "framer-motion";
import SideViewModal from "../DedicatedEventPage/GeneralComponents/SideViewModal";
import Loading from "../../components/Loading";

const SwitchApplications = dynamic(() =>
  import("../../components/NavigationBar/SwitchApplications.js")
);
// import ProfileCard from "../../components/NavigationBar/ProfileCard.js"
const ProfileCard = dynamic(() =>
  import("../../components/NavigationBar/ProfileCard.js"))
const HomeNav = ({ isBgNavyBlue = false, isHomePage, eventDetails,
  user,
  newUserLogin,
  logoWhite,
  color,
  disableLogo = false,
  mobileNavbar = true,
  hideLinks = false,
  eventPage = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const router = useRouter();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Create a ref for the toggle button

  const authContext = useContext(AuthContext);
  const [Open, setOpen] = useCycle(false, true);
  const [isDropdownVisible, setDropdownVisible] = useState(null);
  console.log(authContext, "authcontext")

  const handleLogout = async () => {
    try {
      let message = "Logged Out Successfully";
      successToaster(message);
      authContext.setAuth({ attributes: {} }, false);
      let cookies = Cookies.get();
      Object.keys(cookies).map((key) => {
        if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
      });
      removeCognitoFromLocalStorage();
      Cookies.remove("authToken", { sameSite: "strict" });
      Auth.signOut();
      // router.replace('/', undefined, { shallow: false })
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleMediaQueryChange = (matches) => {
    smallerScreen = matches;
    // matches will be true or false based on the value for the media query
  };
  let smallerScreen = useMediaQuery(
    { maxWidth: 991 },
    undefined,
    handleMediaQueryChange
  );

  let hideProfile = false;

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navStyle = {
    borderBottom: "1px solid #d6d6d6",
    backgroundColor: isBgNavyBlue ? "#002E6E" : "#fff", // Conditional background color
    color: '#fff !important',
  };
  const style = {
    textDecoration: "none",
    color: "white",
  };
  // useEffect(() => {
  //   console.log(authContext, "authContext in HomeNav"); // Check context value changes here
  // }, [authContext.signedIn]);


  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) && // Click is outside the dropdown
      buttonRef.current &&
      !buttonRef.current.contains(event.target) // Click is outside the toggle button
    ) {
      setIsDropdownOpen(false); // Close the dropdown
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or dropdown closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div>

      <header className="px-3 font-figtree px-sm-5 px-lg-5 py-2 py-sm-2 nav-container " style={navStyle}>
        <nav className="d-flex justify-content-between align-items-center text-pricing">
          <div className="left-navItem d-flex align-items-center gap-5">
            {/* Logo */}
            <div>
              <Link href="/">

                {isBgNavyBlue ? <img
                  src="https://d2nnrpx7gk2my2.cloudfront.net/files/670417abc4d989000847b56a/Frame.svg"
                  alt="Nav-brand"
                  style={{ height: '35px' }}
                /> : <img
                  src="https://d2nnrpx7gk2my2.cloudfront.net/files/66e42c3e698d2800087805f2/Frame.svg"
                  alt="Nav-brand"
                  style={{ height: '35px' }}
                />}
              </Link>
            </div>
            {/* Navigation Links */}
            <ul className="d-none d-lg-flex gap-4 align-items-center list-unstyled flex-row my-0">
              {/* Home */}
              {/* <li
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                {isHomePage != true ? <Link href="/home" className={` ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ textDecoration: 'none' }}>
                  Home
                </Link> : ''}
              </li> */}
              {/* Events */}
              <li
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                <Link href="/events" className={` ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ textDecoration: 'none' }}>
                  Events
                </Link>
              </li>
              {/* Features */}
              <li
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                <Link href="/features" className={` ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ textDecoration: 'none' }}>
                  Features
                </Link>
              </li>
              {/* Resources */}
              <li
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                <Link href="/usecases" className={` ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ textDecoration: 'none' }}>
                  Use Cases
                </Link>
              </li>

              {/* Use Cases with Dropdown */}
              <li
                ref={buttonRef}
                onClick={toggleDropdown}
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                <span className={`cursor-pointer ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                  Resources
                </span>
                {isBgNavyBlue ? <span
                  className={`dropdownicon ${isDropdownOpen ? 'icon-open-bt' : 'closed-icon-bt'}`}

                ></span> : <span
                  className={`dropdownicon ${isDropdownOpen ? 'icon-open-wt' : 'closed-icon-wt'}`}

                ></span>}

                {isDropdownOpen && (
                  <div ref={dropdownRef} className="dropdown-menus">
                    <ul className="list-unstyled mb-0">
                      <Link target='_blank' href="https://blog.konfhub.com/" style={{ textDecoration: 'none' }}> <li className="dropdown-item">
                        <img src="/img/Press&Media.svg" className="me-3" alt="Dashboard" />
                        <div>
                          <h6>Blogs</h6>
                          <p>Stay updated with everything new!</p>
                        </div>
                      </li>
                      </Link>
                      <Link target='_blank' href="https://www.youtube.com/c/KonfHubTech" style={{ textDecoration: 'none' }} >
                        <li className="dropdown-item">
                          <img src="/img/ph_video-fill.svg" className="me-3" alt="Reports" />
                          <div>
                            <h6>Videos</h6>
                            <p>Check these videos on using KonfHub</p>
                          </div>
                        </li>
                      </Link>
                    </ul>
                  </div>
                )}
              </li>


              {/* Pricing */}
              <li
                style={{ position: 'relative' }}
                className="d-flex text-nowrap font-sm figtree-family align-items-center fw-medium gap-1"
              >
                <Link href="/pricing" className={` ${isBgNavyBlue ? "text-white" : "text-pricing"}`} style={{ textDecoration: 'none' }}>
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          <div className="right-navItem">
            {/* Right Buttons */}
            <div className="d-none d-lg-flex align-items-center text-nowrap gap-4">
              <a target='_blank' href={`${hostEventUrl}/create/event`} className={`  text-decoration-none ${isBgNavyBlue ? "host-btn-bt" : "host-btn-wt"}`}>
                Host an Event
              </a>
              {authContext.signedIn === null ? (
                <div className=" d-flex justify-content-center align-items-center  ms-3">
                  <div className="me-5"></div>
                  <Loading className={"m-auto"} color="#002e6e" />
                </div>
              ) : authContext.signedIn === true ? (
                <div className=" d-flex justify-content-center align-items-center align-items-lg-baseline ms-3">
                  <div className="me-4 margin-small">
                    <SwitchApplications />
                  </div>
                  <ProfileCard
                    onLogout={(e) => handleLogout(e)}
                    hideProfile={hideProfile}
                  />
                </div>
              ) : (
                <div className="d-flex header-menu-item">
                  <a onClick={handleOnLogin} className={` login-btn text-decoration-none ${isBgNavyBlue ? "text-white" : "text-pricing"}`}>
                    Log in
                  </a>
                </div>
              )}

            </div>
            <div className='d-flex align-item-center gap-2'>
              <div className="d-lg-none d-block me-4 margin-small">
                <SwitchApplications />
              </div>
              {!isBgNavyBlue ? <img className="d-block d-lg-none" src="/img/menu.svg" alt="Menu" onClick={toggleDrawer} /> : <img className="d-block d-lg-none" src="https://d2nnrpx7gk2my2.cloudfront.net/files/670d4ef94208120008fdb367/_Navmenubutton.svg" alt="Menu" onClick={toggleDrawer} />}
            </div>


            {/* Drawer Menu */}
            {isOpen && <DrawerMenu onClose={toggleDrawer} handleLogout={handleLogout} isHomePage={isHomePage} />}
          </div>
        </nav>
      </header>


      <style jsx>{`
        .nav-container {
          border-bottom: 1px solid #d6d6d6;
        }
        .dropdownicon {
          //background: url('/img/dropdown.svg') no-repeat;
          height: 20px;
          width: 20px;
          display: inline-block;
          cursor: pointer;
        }
        .icon-open-wt{
          background: url('/img/dropdown.svg') no-repeat;
          transform: rotate(-180deg);
        }
        .closed-icon-wt{
          background: url('/img/dropdown.svg') no-repeat;
          
        }
        .icon-open-bt{
          background: url('https://d2nnrpx7gk2my2.cloudfront.net/files/67041ca665e38f0008782282/chevron-down.svg') no-repeat;
          transform: rotate(-180deg);
        }
        .closed-icon-bt{
          background: url('https://d2nnrpx7gk2my2.cloudfront.net/files/67041ca665e38f0008782282/chevron-down.svg') no-repeat;
          
        }
        .dropdown-menus {
          position: absolute;
          top: 30px;
          left: -20px;
          background: white;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          padding: 10px 10px;
          z-index: 100;
          width: 350px;
          opacity: 1;
          transform: translateY(10px);
          transition: opacity 0.3s, transform 0.3s;
          display: block;
        }
        .dropdown-item {
          display: flex;
          align-items: start;
          padding: 10px;
          margin-bottom: 5px;
        }
        .dropdown-item:hover {
          background: #c5e2ff3d;
          color: black;
          border-radius: 8px;
        }
        .dropdown-item img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        .dropdown-item h6 {
          margin: 0;
          font-size: 16px;
        }
        .dropdown-item p {
          margin: 0;
          font-size: 12px;
          color: #666;
        }
        .host-btn-wt {
          padding: 8px 10px;
          border: 1px solid ${websiteColors[theme].textSecondary};
          border-radius: 4px;
          text-decoration: none;
          color: #0f0f0f;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 600;
          line-height: 28px;
        }
        .host-btn-bt {
          padding: 8px 10px;
          border: 1px solid #fff;
          border-radius: 4px;
          text-decoration: none;
          color: #fff;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 600;
          line-height: 28px;
        }
        @media screen and (max-width: 1045px) {
          .left-navItem {
            gap: 16px !important;
          }
        }
        .login-btn {
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default HomeNav;