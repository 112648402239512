import React, { useContext, useState } from 'react';
import { theme, websiteColors } from "../../themes/Theme.js";
import Link from "next/link";
import { useRouter } from "next/router";
import { hostEventUrl, loginUrl } from '../../config/config.js';
import AuthContext from "../../contexts/AuthContext";
import { handleOnLogin } from '../../helpers/utils.js';

const DrawerMenu = ({ onClose, isHomePage, handleLogout }) => {
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const authContext = useContext(AuthContext);


  const toggleResources = () => {
    setIsResourcesOpen(!isResourcesOpen);
  };

  return (
    <div className='overlay font-figtree'>
      <div
        className={`offcanvas offcanvas-end show`}
        style={{
          visibility: 'visible',
          backgroundColor: '#fff',
          transition: '0.5s',
        }}
      >
        <div className="offcanvas-header">
          <button type="button" className="btn-close" onClick={onClose}></button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column gap-3 mb-4">
            {/* <button className="host-btn fw-bold font-sm">Host an Event</button>
            <button className="demo-btn fw-bold font-sm">Log in</button> */}
            {authContext.signedIn === true && (
              <div className="d-flex px-1 py-2 text-left switchEvent-pad pt-3">
                <div className=" pr-0">
                  <img
                    className="profile-dropDown-img profile-container"
                    src={
                      authContext.user.attributes[
                      "custom:profilePicture"
                      ]
                    }
                    alt="Propfile P"
                  />
                </div>
                <div className=" details-container ms-2 pl-0 pr-0 mt-auto mb-auto">
                  <p
                    className="h5 profile-email mb-0"
                    style={{ color: "#572148" }}
                  >
                    {authContext?.user.attributes.name}
                  </p>
                  <p
                    className="profile-sub-text mb-0"
                    style={{ color: "#808080" }}
                  >
                    {authContext?.user.attributes.email}
                  </p>
                </div>
              </div>
            )}
            <a href={`${hostEventUrl}/create/event`} className={`  text-decoration-none host-btn-wt host-btn fw-bold font-sm `}>
              Host an Event
            </a>
            {authContext.signedIn === false && (
              <a onClick={handleOnLogin} className={` login-btn text-decoration-none text-pricing demo-btn fw-bold font-sm }`}>
                Log in
              </a>)}
          </div>

          <ul className="list-group list-unstyled">
            {/* <li className="list-group-item">
              {isHomePage !== true ? <Link href="/home" className="text-pricing" style={{ textDecoration: 'none' }}>
                Home
              </Link> : ""}
            </li> */}
            <li className="list-group-item">
              <Link href="/events" className="text-pricing" style={{ textDecoration: 'none' }}>
                Events
              </Link>
            </li>
            <li className="list-group-item">
              <Link href="/features" className="text-pricing" style={{ textDecoration: 'none' }}>
                Features
              </Link>
            </li>
            <li className="list-group-item">
              <Link href="/usecases" className="text-pricing" style={{ textDecoration: 'none' }}>
                Use Cases
              </Link>
            </li>
            <li>
              <div
                className="d-flex justify-content-between align-items-center list-group-item"
                onClick={toggleResources}
                style={{ cursor: 'pointer' }}
              >
                <span className='text-pricing'>Resources</span>
                <span>
                  {isResourcesOpen ? (
                    <img src="/img/cheveron-up.svg" alt="" />
                  ) : (
                    <img src="/img/cheveron-down.svg" />
                  )}
                </span>
              </div>
              {isResourcesOpen && (
                <div style={{ backgroundColor: '#FAFBFD' }}>
                  <ul
                    className="list-group list-unstyled dropdown-items text-pricing ps-0"
                    style={{ backgroundColor: '#FAFBFD' }}
                  >
                    <li className="list-group-item dropdown-items">
                      <Link target='_blank' href="https://blog.konfhub.com/" className='text-pricing' style={{ textDecoration: 'none' }}>
                        <img src="/img/Press&Media.svg" className="me-3 text-pricing" alt="" />
                        Blogs
                      </Link>
                    </li>
                    <li className="list-group-item dropdown-items text-pricing">
                      <Link target='_blank' href="https://www.youtube.com/c/KonfHubTech" className='text-pricing' style={{ textDecoration: 'none' }}>
                        <img src="/img/ph_video-fill.svg" className="me-3" />
                        Videos
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li className="list-group-item">
              <Link href="/pricing" className='text-pricing' style={{ textDecoration: 'none' }}>Pricing</Link>
            </li>
            {authContext.signedIn === true && (
              <div
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "700",
                  letterSpacing: "0px",
                }}
              >
                <hr className="mt-2 nav-mob" />
                <a
                  href={`${hostEventUrl}`}
                  target="_blank"
                  className="text-decoration-none d-block pb-2"
                  style={{ color: "#572148" }}
                >
                  <span className="text-pricing">Dashboard</span>{" "}
                </a>
                <Link href="/profile">
                  <span
                    className="d-block text-pricing py-2"
                    style={{ color: "#572148" }}
                  >
                    Manage Profile
                  </span>
                </Link>
                <hr className="mt-2 nav-mob" />
                <span
                  className="me-2  py-2"
                  style={{
                    color: "#572148",
                    cursor: "pointer",
                    letterSpacing: "0px",
                  }}
                  onClick={(e) => handleLogout(e)}
                >
                  Logout <img src="https://d2nnrpx7gk2my2.cloudfront.net/files/672c650d97b04e000924da19/Logout.svg" alt="logout icon" />
                </span>
              </div>
            )}
          </ul>
        </div>
      </div>

      <div className="offcanvas-backdrop fade show" onClick={onClose}></div>

      <style jsx>{`
        .offcanvas {
          width: 300px;
          background: white;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1045;
          transition: transform 0.5s ease;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
        .show {
          transform: translateX(0);
        }
        .offcanvas-header {
          display: flex;
          justify-content: space-between;
          padding: 15px;
        }
        .offcanvas-body {
          padding: 20px;
        }
        .btn-close {
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 20px;
        }
        .btn-outline-primary {
          border: 1px solid #0D6EFD;
          color: #0D6EFD;
          background-color: transparent;
          padding: 10px 15px;
          border-radius: 5px;
        }
        .btn-primary {
          background-color: #0D6EFD;
          color: white;
          padding: 10px 15px;
          border-radius: 5px;
        }
        .list-group-item {
          border: none;
          padding: 10px 0;
          font-size: 16px;
          font-weight: 600;
          line-height: 26.4px;
        }
        .dropdown-items {
          background-color: #FAFBFD !important;
        }
        .list-group-item a {
          text-decoration: none;
          color: ${websiteColors[theme].textSecondary};
        }
        .list-group-item:hover a {
          color: #0D6EFD;
        }
        .list-group-item a:hover {
          text-decoration: underline;
        }
        .btn-close {
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 15px;
        }
        /* Overlay when drawer is open */
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 999;
        }
        .host-btn {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 600;
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
          color: #fff;
          padding: 10px 40px;
          border-radius: 4px;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        .demo-btn {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 600;
             display:flex;
          align-items:center;
          justify-content:center;
          //color: #002E6E;
          color: ${websiteColors[theme].primaryWebsiteTheme};
          padding: 10px 40px;
          border-radius: 4px;
          border: 1px solid ${websiteColors[theme].primaryWebsiteTheme};
          background-color: #fff;
        }
        .login-btn {
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default DrawerMenu;
